import { dateToBazi } from "../FourPillar"
import {
  EB,
  HS,
  EARLY_HEAVEN_TRIGRAM,
  SIX_ANIMALS,
  EBElement,
} from "../constants"
import Hexagram from "./Hexagram.json"

const elementRelationship = (first, second) => {
  const lookup = ["water", "wood", "fire", "earth", "metal"]
  const firstIndex = lookup.findIndex(e => e === first)
  const distance = lookup
    .slice(firstIndex)
    .concat(lookup.slice(0, firstIndex))
    .findIndex(e => e === second)

  if (distance === 0) {
    return "兄"
  }
  if (distance === 1) {
    return "子"
  }
  if (distance === 2) {
    return "妻"
  }
  if (distance === 3) {
    return "官"
  }
  if (distance === 4) {
    return "父"
  }
}

export const dateToHexagram = date => {
  const bazi = dateToBazi(date)
  const { lunarMonthNumber, lunarDay } = bazi
  const [yStem, yBranch] = bazi.GanZhiYear.ganzhi.split("")
  const [dStem, dBranch] = bazi.GanZhiDay.ganzhi.split("")
  const [hStem, hBranch] = bazi.GanZhiHour.ganzhi.split("")
  const yearNum = EB.findIndex(e => e === yBranch) + 1
  const hourNum = EB.findIndex(e => e === hBranch) + 1

  const sumOfYMD = lunarDay + lunarMonthNumber + yearNum

  const sumOfYMDH = sumOfYMD + hourNum
  const upperTrigram = (sumOfYMD - 1) % 8
  const lowerTrigram = (sumOfYMDH - 1) % 8
  const changingLine = (sumOfYMDH - 1) % 6

  const upper =
    EARLY_HEAVEN_TRIGRAM[Object.keys(EARLY_HEAVEN_TRIGRAM)[upperTrigram]]
  const lower =
    EARLY_HEAVEN_TRIGRAM[Object.keys(EARLY_HEAVEN_TRIGRAM)[lowerTrigram]]
  const mainHexagram = `${lower}${upper}`
  const changeHexagram = changeLine(mainHexagram, changingLine)
  const changeHexagramObject = getHex(changeHexagram)
  const mainHexagramObject = getHex(mainHexagram)

  const getTrigram = binary =>
    Object.keys(EARLY_HEAVEN_TRIGRAM).find(
      e => EARLY_HEAVEN_TRIGRAM[e] === binary
    )
  return {
    mainHexagram: {
      ...mainHexagramObject,
      relation: mainHexagramObject.Branch.split("").map(ee =>
        elementRelationship(mainHexagramObject.element, EBElement[ee])
      ),
      upper: getTrigram(upper),
      lower: getTrigram(lower),
    },
    changeHexagram: {
      ...changeHexagramObject,
      relation: changeHexagramObject.Branch.split("").map(ee =>
        elementRelationship(mainHexagramObject.element, EBElement[ee])
      ),
      upper: getTrigram(changeHexagram.substr(3, 3)),
      lower: getTrigram(changeHexagram.substr(0, 3)),
    },
    changingLine,
    animals: getSixAnimal(dStem),
  }
}

export const getHex = binary => {
  return searchHexagram("Binary", binary.split("").join(""))
}

export const getSixAnimal = dayMaster => {
  const lookup = {
    甲: 0,
    乙: 0,
    丙: 1,
    丁: 1,
    戊: 2,
    己: 3,
    庚: 4,
    辛: 4,
    壬: 5,
    癸: 5,
  }
  const index = lookup[dayMaster]
  return SIX_ANIMALS.slice(index).concat(SIX_ANIMALS.slice(0, index))
}

export const getEB = hexagramBinary => {
  const lowerTrigram = hexagramBinary.substr(0, 3)
  const upperTrigram = hexagramBinary.substr(3, 3)
  const lookup = {
    "111": 0,
    "100": 0,
    "001": 4,
    "010": 2,
    "000": 7,
    "101": 3,
    "011": 1,
    "110": 5,
  }
  const lowerStart = lookup[lowerTrigram]
  const upperStart = lookup[upperTrigram]

  return [...Array(6).keys()].map(i => {
    if (i < 3) {
      const isYin = [0, 5, 3, 6].includes(parseInt(lowerTrigram, 2))
      if (isYin) {
        const seq = EB.slice().reverse()
        return seq[(11 - lowerStart + i * 2) % 12]
      } else {
        return EB[(lowerStart + i * 2) % 12]
      }
    } else {
      const isYin = [0, 5, 3, 6].includes(parseInt(upperTrigram, 2))
      if (isYin) {
        const seq = EB.slice().reverse()
        return seq[(11 - upperStart + i * 2) % 12]
      } else {
        return EB[(upperStart + i * 2) % 12]
      }
    }
  })
}

const changeLine = (hexagram, lineIndex) => {
  const replace = hexagram[lineIndex] === "1" ? "0" : "1"
  return replaceAt(hexagram, lineIndex, replace)
}

export const searchHexagram = (key, value) => {
  return Hexagram.find(h => h[key] === value)
}

const replaceAt = (word, index, replacement) => {
  return (
    word.substr(0, index) +
    replacement +
    word.substr(index + replacement.length)
  )
}
