import React, { useState, useEffect } from "react"
import Layout from "../../../containers/layout"
import Bazi from "../../../components/Bazi"
import { dateToHexagram } from "../../../cm-lib/IChing"
import DatePicker from "../../../components/DateTimePicker"
import { Hexagram, Line, Trigram } from "../../../containers/IChing/utils"
import Hex from "../../../containers/IChing/Hex"
import { Box, Accordion, AccordionPanel } from "grommet"
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  READABLE_DATETIME_FORMAT,
} from "../../../constants"
import { assign, getQueryString, parseStringDate } from "../../../helpers"
import { format } from "date-fns"
import Ad from "../../../containers/Ad"

const queryString = require("query-string")

const binToHexagram = lines => {
  const lowerLines = lines
    .slice(0, 3)
    .map(e => (e === "0" ? Line.Broken : Line.Straight))
  const upperLines = lines
    .slice(3, 6)
    .map(e => (e === "0" ? Line.Broken : Line.Straight))
  return new Hexagram(new Trigram(lowerLines), new Trigram(upperLines))
}

const Home = () => {
  const qs = getQueryString()
  const queryDate = parseStringDate(qs.search, new Date())
  const [hex, setHex] = useState(null)
  useEffect(() => {
    const ii = dateToHexagram(queryDate)
    const mainHex = binToHexagram(ii.mainHexagram.Binary.split(""))
    const changeHex = binToHexagram(ii.changeHexagram.Binary.split(""))
    console.log(ii)
    setHex([
      {
        hex: mainHex,
        changeLine: 5 - ii.changingLine,
        data: ii.mainHexagram,
        sixGod: ii.animals,
      },
      {
        hex: changeHex,
        data: ii.changeHexagram,
        sixGod: ii.animals,
      },
    ])
  }, [])

  return (
    <Layout>
      <h2>Plum Blossom Divination</h2>
      <Accordion background="light-2">
        <AccordionPanel label="How The Hexagram Is Derived">
          <Box>
            <ol>
              <li>
                {" "}
                1 - Qian, 2 - Dui, 3 - Li, 4 - Zhen, 5 - Xun, 6 - Kan, 7 - Gen,
                8 - Kun
              </li>
              <li>
                Basing on the time the question is asked, get the bazi chart
              </li>
              <li>
                For year and day, get the branch number (1 - 12), for month and
                day get the lunar month and day
              </li>
              <li>
                <strong>Upper Trigram</strong> = year + month + day divide by 8
                and get the remainder, if remainder is 0, take it as 8
              </li>
              <li>
                <strong>Lower Trigram</strong> = year + month + day + hour
                divide by 8 and get the remainder, if remainder is 0, take it as
                8
              </li>
              <li>
                <strong>Changing Line</strong> = year + month + day + hour
                divide by 6 and get the remainder, if remainder is 0, take it as
                6
              </li>
            </ol>
          </Box>
        </AccordionPanel>
      </Accordion>
      <DatePicker
        dateObject={queryDate}
        onClick={date =>
          assign(
            "?" +
              queryString.stringify({
                search: format(date, DATETIME_FORMAT),
              })
          )
        }
      />

      <Bazi inputDate={queryDate} hideOtherInformation={true} plumNumber />
      <Box direction="row" alignContent="stretch">
        {hex &&
          hex.map(e => (
            <Box fill={true} pad="medium">
              <Hex
                data={e.data}
                hexagram={e.hex}
                changeLine={e.changeLine}
                sixGod={e.sixGod}
              />
            </Box>
          ))}
      </Box>
      <Ad />
    </Layout>
  )
}

export default Home
