import React, { useState, useEffect } from "react"
import { Accordion, AccordionPanel } from "grommet"
import DateTimePicker from "../../components/DateTimePicker"
import Pillar from "../../components/Pillar"
import BaziPillar from "../../containers/Bazi/BaziPillar"
import BoxButton from "../../components/BoxButton"
import { EB } from "../../cm-lib/constants"
import { dateObjectToBazi } from "../../cm-lib/FourPillar"
import { useTranslation } from "react-i18next"
import { parseStringDate, getQueryString, chunk } from "../../helpers"
import { format } from "date-fns"

const isBrowser = typeof window !== `undefined`

const Home = ({ inputDate, onBazi, hideOtherInformation, plumNumber }) => {
  const { t } = useTranslation()
  const qs = getQueryString()
  const [dateObject] = useState(inputDate || new Date())
  const [pillars, setPillars] = useState(null)
  const [gender, setGender] = useState(parseInt(qs.g) || 0)
  const [bazi] = useState(
    dateObjectToBazi(dateObject, gender === 0 ? "M" : "F")
  )
  useEffect(() => {
    const bz = [
      {
        header: "Hour",
        ganzhi: bazi.GanZhiHour.ganzhi,
        custom: EB.indexOf(bazi.GanZhiHour.ganzhi.substr(1, 1)) + 1,
      },
      {
        header: "Day",
        ganzhi: bazi.GanZhiDay.ganzhi,
        custom: `Lunar: ${bazi.lunarDay}`,
      },
      {
        header: "Month",
        ganzhi: bazi.GanZhiMonth.ganzhi,
        custom: `Lunar: ${bazi.lunarMonthNumber}`,
      },
      {
        header: "Year",
        ganzhi: bazi.GanZhiYear.ganzhi,
        custom: EB.indexOf(bazi.GanZhiYear.ganzhi.substr(1, 1)) + 1,
      },
    ].map(e => (
      <BaziPillar
        custom={e.custom}
        plumNumber
        hiddenStems
        header={e.header}
        ganzhi={e.ganzhi}
      />
    ))

    const luckHeader = bazi.tenYearCycle.map((e, i) => {
      return <BaziPillar header={bazi.startWesternAge + i * 10} ganzhi={e} />
    })

    setPillars({
      bz,
      hundredYear: chunk(
        bazi.hundredYear.map(e => (
          <BaziPillar header={e.year} ganzhi={e.pillar} />
        )),
        10
      ),
      luckHeader,
      bazi,
    })
  }, [bazi])
  return (
    <>
      {!hideOtherInformation && (
        <BoxButton
          activeIndex={gender}
          onClick={(e, i) => setGender(i)}
          buttons={[
            {
              label: "M",
            },
            {
              label: "F",
            },
          ]}
        />
      )}
      <br />
      {pillars && <Pillar list={pillars.bz} plumNumber />}

      {!hideOtherInformation && (
        <Accordion>
          <AccordionPanel label="More Information">
            {Object.keys(bazi.stars.classical).map(k => (
              <AccordionPanel
                label={`${k} ${t(k)}: ${bazi.stars.classical[k]}`}
              ></AccordionPanel>
            ))}
          </AccordionPanel>
        </Accordion>
      )}
      <br />
      {!hideOtherInformation && pillars && <Pillar list={pillars.luckHeader} />}

      <br />
      {!hideOtherInformation &&
        pillars &&
        pillars.hundredYear.map(e => <Pillar list={e} />)}
    </>
  )
}

export default Home
