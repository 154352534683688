import React from "react"
import { Box, Text } from "grommet"
import { Hexagram, Line } from "./utils"
import HexLine from "./HexLine"
import { HexagramWrapper, Title } from "./Hex.css"

interface Props {
  hexagram: Hexagram
}

const Hex = ({ hexagram, changeLine, data, sixGod }) => (
  <>
    <Box align="center" justify="center">
      {data.TC}
    </Box>
    <Box align="center" justify="center">
      {hexagram.hexagramName}
    </Box>
    {hexagram.getLinesDescending().map((line: Line, i) => {
      const inverseIndex = 5 - i
      return (
        <HexLine
          kin={data.relation[inverseIndex]}
          branch={data.Branch[inverseIndex]}
          shen={sixGod && sixGod[inverseIndex]}
          host={data.host - 1 === inverseIndex}
          guest={data.guest - 1 === inverseIndex}
          line={line}
          change={i === changeLine}
        />
      )
    })}
  </>
)

export default Hex
