import styled from "styled-components"
import React from "react"

export const BrokenContainer = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
  justify-content: space-between;
  margin: 10px auto;
`

export const BrokenSegment = styled.div`
  height: 100%;
  width: ${props => (props.mend ? "71%" : "32%")};
  background-color: ${props => (props.change ? "tomato" : "darkslategrey")};
  color: white;
  text-align: ${props => (props.left ? "left" : "right")};
  display: flex;
`

const SegmentSymbol = styled.div`
  ${props => (props.right ? "margin-left: auto;" : "")}
`

export const Broken = ({ change, branch, host, guest, kin, mend, shen }) => (
  <BrokenContainer mend={mend}>
    {shen}
    {!mend && (
      <BrokenSegment mend={mend} left={true} change={change}>
        {host && "S"}
        {guest && "O"}
      </BrokenSegment>
    )}
    <BrokenSegment mend={mend} margin change={change}>
      <SegmentSymbol>
        {mend && host && "S"}
        {mend && guest && "O"}
      </SegmentSymbol>
      <SegmentSymbol right>{kin}</SegmentSymbol>
    </BrokenSegment>
    {branch}
  </BrokenContainer>
)

export const Straight = props => <Broken {...props} mend={true} />
